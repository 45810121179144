<template>
  <div>
    <v-row>
      <v-col sm="10">
        <Heading title="Марки счётчиков" />
      </v-col>

      <v-col
        align="right"
        align-self="center"
      >
        <v-btn
          color="blue"
          outlined
          @click="onAddClick"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-if="!isLoaded"
      :loading="!isLoaded"
      type="table-tbody"
    />

    <v-card v-for="brand in meter_brands" :key="brand.id" tile>
      <v-card-text class="d-flex">
        <v-row>
          <v-col sm="3">
            {{ brand.id }}
          </v-col>

          <v-col sm="3">
            {{ brand.name }}
          </v-col>

          <v-col sm="3">
            {{ brand.type ? brand.type.name : '-' }}
          </v-col>
        </v-row>

        <div class="buttons">
          <v-btn
            text
            small
            @click="onEditClick(brand.id)"
          >
            Редактировать
          </v-btn>

          <v-btn
            text
            small
            @click="onDeleteClick(brand.id)"
          >
            Удалить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <MeterModal
      :meter="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { MeterBrandsService } from '@/common/api.service'
import DeleteConfirmation from '@/components/Modals/DeleteConfirmation'
import MeterModal from '@/views/Director/MeterBrands/MeterModal'

export default {
  components: {
    DeleteConfirmation,
    MeterModal
  },

  data () {
    return {
      meter_brands: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      deleteConfirmationVisible: false
    }
  },

  methods: {
    onAddClick () {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm () {
      this.deleteConfirmationVisible = false
      MeterBrandsService.delete(this.toDeleteId).then(() => this.fetchMeterBrands())
    },

    deleteDecline () {
      this.deleteConfirmationVisible = false
    },

    onModalClose (needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.meter_brands = []
        this.isLoaded = false
        this.fetchMeterBrands()
      }
    },

    fetchMeterBrands () {
      MeterBrandsService
        .get()
        .then(({ data }) => {
          this.meter_brands = data
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem () {
      return this.toEditId !== 0 ? this.meter_brands.find(meter => meter.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchMeterBrands()
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
}
</style>
